import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { OnboardingTitle } from "~/ui/Onboarding"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import {
  AhoyEventTypeEnum,
  DebugSettingKeyEnum,
  OnboardingStateEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { MembershipCard } from "~/users/MembershipCard"
import { useCommunity, useDebugSettings } from "~/community/useCommunity"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useQuery } from "@apollo/client"
import { CURRENT_USER_QUERY_DOCUMENT } from "~/auth/currentUserQuery"
import { useQaFitHelper } from "~/users/useQaFitHelper"
import { Button } from "~/shadcn/ui/button"
import { useEffect, useRef, useState } from "react"
import { cn } from "~/common/shadcn-utils"
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import { feedPath, unfitPath } from "~/common/paths"
import { Trans, useTranslation } from "react-i18next"
import { useLogEvent } from "~/analytics/EventsContext"
import { z } from "zod"
import { FormProvider, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { OnboardingBioField } from "~/components/onboarding/OnboardingBioField"
import { OnboardingCard } from "~/components/onboarding/OnboardingCard"
import { Check } from "lucide-react"
import toast from "react-hot-toast"
import { Transition } from "@headlessui/react"
import { useConfirm } from "~/ui/Confirm"

export const FitWaitingScreen = () => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser.fit === false) {
      navigate(unfitPath.pattern)
    }
  }, [navigate, currentUser])

  return (
    <OnboardingLayout step={4} hideCommunityFooter>
      {currentUser.fit ? <CardAndVideo /> : <Waiting />}
    </OnboardingLayout>
  )
}

const CardAndVideo = () => {
  const community = useCommunity()
  const [isFlipped, setIsFlipped] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation("community")
  const { logEvent } = useLogEvent()

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  const onFlip = () => {
    logEvent(AhoyEventTypeEnum.PageViewed, {
      sub_page: "You're In",
    })
    setIsFlipped(true)
  }

  const onComplete = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          onboardingState: OnboardingStateEnum.Completed,
        },
      },
    })
    if (errors) {
      displayErrors(errors)
    } else {
      navigate(feedPath.pattern)
    }
  }

  return (
    <>
      <OnboardingTitle>Welcome to {community.name}</OnboardingTitle>

      <div className="flex flex-col items-center">
        <div
          className={cn(
            "flip-card w-full md:w-[533px]",
            isFlipped && "flipped"
          )}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <MembershipCard containerClassName="md:w-full h-full md:h-full" />
            </div>
            <div className="flip-card-back">
              <div className="rounded-xl overflow-hidden w-full h-full">
                <ReactPlayer
                  height={"100%"}
                  width={"100%"}
                  controls={true}
                  playing={isFlipped}
                  url={
                    community.welcomeVideoUrl ||
                    "https://workweek-public.s3.us-east-2.amazonaws.com/web-app-v1.mp4"
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {isFlipped ? (
          <>
            <div className="mt-12 text-center max-w-[450px]">
              <Trans
                i18nKey="videoIntro"
                ns="onboarding"
                values={{ creatorName: t("creatorFirstName") }}
              />
            </div>
            <Button
              variant="onboarding"
              className="mt-14 py-3 px-7"
              onClick={onComplete}
            >
              Go to {community.name}
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="onboarding"
              className="mt-14 py-3 px-7"
              onClick={onFlip}
            >
              Continue
            </Button>
          </>
        )}
      </div>
    </>
  )
}

const bioFormSchema = z.object({
  bio: z.string(),
})

export type BioFormValues = z.infer<typeof bioFormSchema>

const Waiting = () => {
  const { debugSettings } = useDebugSettings()
  const { openSubscriptionWizard } = useSubscription()
  const { currentUser } = useCurrentUser()
  useQuery(CURRENT_USER_QUERY_DOCUMENT, { pollInterval: 30000 })
  const { qaMarkFit } = useQaFitHelper()
  const [loading, setLoading] = useState(false)
  const { areApplicationsPaused } = useCommunity()

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  const [needsBio, setNeedsBio] = useState(!currentUser.bio)
  const [bioSubmitted, setBioSubmitted] = useState(false)
  const showConfirm = useConfirm()

  const form = useForm<BioFormValues>({
    resolver: zodResolver(bioFormSchema),
    defaultValues: {
      bio: currentUser.bio || "",
    },
  })

  const onSubmit = async (formData: BioFormValues) => {
    setLoading(true)
    await runUserUpdate({
      variables: {
        input: formData,
      },
    })
    setBioSubmitted(true)
    toast.success("Bio updated!")
    setLoading(false)
    setTimeout(() => {
      setNeedsBio(false)
    }, 2000)
  }

  const { t } = useTranslation("onboarding")
  const hasOpenedApplicationsPausedModalRef = useRef(false)
  useEffect(() => {
    if (areApplicationsPaused && !hasOpenedApplicationsPausedModalRef.current) {
      hasOpenedApplicationsPausedModalRef.current = true
      showConfirm({
        title: t("applicationsPausedModal.title"),
        body: (
          <div className="flex flex-col gap-4 my-4">
            <Trans ns="onboarding" i18nKey="applicationsPausedModal.body" />
          </div>
        ),
        confirmText: t("applicationsPausedModal.confirmText"),
        showCancel: false,
        showConfirm: true,
        titleClassName: "sm:text-center",
        footerClassName: "sm:justify-center",
      })
    }
  }, [areApplicationsPaused, showConfirm, t])

  return (
    <>
      <OnboardingTitle>Let's go! Thank you.</OnboardingTitle>

      <div className="text-center mb-10">
        Our membership team is reviewing your responses.
        <br />
        We'll get back to you within{" "}
        <span className="font-bold">
          {currentUser.tier?.waitPeriodHours}
        </span>{" "}
        hours via email.
      </div>

      <div className="flex justify-center">
        <MembershipCard />
      </div>

      {currentUser.tier && currentUser.tier.level !== TierLevelEnum.Pro && (
        <div className="font-medium text-center mt-14">
          Want to skip the line and hear back sooner?
          <br />
          <span
            className="cursor-pointer underline"
            onClick={() =>
              openSubscriptionWizard("PricingTableStep", {
                source: "FitWaitingScreen",
              })
            }
          >
            Upgrade Subscription
          </span>
        </div>
      )}

      {currentUser.tier?.level === TierLevelEnum.Pro && (
        <div className="font-medium text-center mt-6">
          There is so much to look forward to. <br />
          See you in your inbox soon.
        </div>
      )}

      <Transition
        show={needsBio}
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <div className="font-medium text-center my-8">
            In the meantime, share a short bio? It helps us get to know you a
            bit better. It'll also live on your profile, but don't worry, you
            can update it later anytime!
          </div>
          <div className="relative">
            <OnboardingCard
              user={currentUser}
              formData={{ id: currentUser.id, bio: form.watch("bio") }}
            />
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <OnboardingBioField />
                <div className="text-center mt-4 mb-10 text-xs">
                  <Button
                    variant="onboarding"
                    type="submit"
                    disabled={
                      loading || bioSubmitted || !form.watch("bio")?.length
                    }
                    size="lg"
                  >
                    {bioSubmitted ? (
                      <Check className="w-4 h-4" />
                    ) : (
                      "Submit Bio"
                    )}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </Transition>

      {debugSettings &&
        debugSettings[DebugSettingKeyEnum.MarkFitOnWaitingScreen] && (
          <div className="fixed bottom-[20px] right-[20px]">
            <Button
              onClick={() => qaMarkFit({ fit: false })}
              variant="destructive"
            >
              Mark Unfit (QA)
            </Button>
            <Button
              onClick={() => qaMarkFit({ fit: true })}
              variant="success"
              className="ml-2"
            >
              Mark Fit (QA)
            </Button>
          </div>
        )}
    </>
  )
}
