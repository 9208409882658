import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { AutomationCreateInput } from "~/__generated__/graphql"
import { AutoMessageForm } from "~/admin/AutoMessageForm"
import { adminAutoMessagesPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"

export const AdminAutoMessagesNewScreen = () => {
  const navigate = useNavigate()
  const [runCreateAutomation] = useSafeMutation(AUTOMATION_CREATE_MUTATION)

  const onSubmit = async (input: AutomationCreateInput) => {
    const { errors } = await runCreateAutomation({
      variables: {
        input: input,
      },
    })

    if (errors) {
      toast.error("Failed to create auto message")
      console.log(errors)
    } else {
      toast.success("Created auto message")
      navigate(adminAutoMessagesPath({ "activeTab?": "" }))
    }
  }

  return (
    <div className="container mx-auto px-8">
      <h1 className="text-3xl font-serif text-foreground mt-16 mb-8">
        New Auto Message
      </h1>

      <AutoMessageForm onSubmit={onSubmit} />
    </div>
  )
}

const AUTOMATION_CREATE_MUTATION = gql(`
  mutation AutomationCreate($input: AutomationCreateInput!) {
    automationCreate(input: $input) {
      automation {
        ...Automation_Admin
      }
    }
  }
`)
