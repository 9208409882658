import { useEffect, useState } from "react"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/shadcn-utils"
import { useLazyQuery } from "@apollo/client"
import AsyncSelect from "react-select/async"
import { useCallback } from "react"
import { MENTION_USER_QUERY_DOCUMENT } from "~/post-composer/useMentionDataSource"
import { useDebouncedCallback } from "use-debounce"
import { User_AvatarFragment } from "~/__generated__/graphql"
import { gql } from "~/__generated__"

type UserOption = {
  value: string
  label: string
}

export const UserSelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  required,
  label,
  labelClassName,
  inputClassName,
  containerClassName,
  initialValue,
  placeholder,
  onUserSelected,
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  containerClassName?: string
  description?: string
  required?: boolean
  control: Control<TFieldValues> | undefined
  name: TName
  initialValue?: { value: string; label: string } | string
  placeholder?: string
  onUserSelected?: (user: User_AvatarFragment) => void
}) => {
  const [selectedUserOption, setSelectedUserOption] =
    useState<UserOption | null>(null)
  const [selectedUser, setSelectedUser] = useState<User_AvatarFragment | null>(
    null
  )
  const [users, setUsers] = useState<User_AvatarFragment[]>([])

  const [getCollaboratorSearch] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: { excludeSelf: false },
  })
  const [getUser] = useLazyQuery(USER_SELECT_FIELD_QUERY_DOCUMENT)

  useEffect(() => {
    ;(async () => {
      let userId: string | null = null

      if (initialValue && typeof initialValue === "string") {
        userId = initialValue
      } else if (initialValue && typeof initialValue === "object") {
        userId = initialValue.value
      }

      if (userId) {
        const { data } = await getUser({ variables: { userId } })
        if (data?.user) {
          setSelectedUser(data.user)
          setSelectedUserOption({
            value: data.user.id,
            label: data.user.name || "",
          })
        }
      }
    })()
  }, [initialValue, getUser])

  useEffect(() => {
    if (onUserSelected && selectedUser) {
      onUserSelected(selectedUser)
    }
  }, [onUserSelected, selectedUser])

  const _loadOptions = useCallback(
    (query: string, callback: (options: UserOption[]) => void) => {
      getCollaboratorSearch({
        variables: { query },
      }).then(({ data }) => {
        if (!data) {
          callback([])
        } else {
          setUsers((users) => {
            const newUsers = data.users.nodes.filter(
              (u) => !users.some((user) => user.id === u.id)
            )
            return [...users, ...newUsers]
          })
          callback(
            data.users.nodes.map((u) => ({
              value: u.id,
              label: u.name || "",
            }))
          )
        }
      })
    },
    [getCollaboratorSearch]
  )
  const loadOptions = useDebouncedCallback(_loadOptions, 400)

  if (initialValue && !selectedUserOption) {
    return null
  }

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={labelClassName}>
            {label}
            {required && <span className="text-red-500 pl-1">*</span>}
          </FormLabel>
          <FormControl>
            <AsyncSelect
              classNamePrefix="select"
              className={cn("flex-1", inputClassName)}
              placeholder={placeholder}
              value={selectedUserOption}
              loadOptions={loadOptions}
              onChange={(selection) => {
                if (selection?.value) {
                  const user = users.find((u) => u.id === selection.value)
                  if (user) {
                    setSelectedUser(user)
                  }
                  field.onChange(selection.value)
                  setSelectedUserOption(selection)
                } else {
                  setSelectedUser(null)
                }
              }}
              styles={{
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "unset",
                }),
                control: (baseStyles, _state) => ({
                  ...baseStyles,
                  outline: "none !important",
                  boxShadow: "none",
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 6,
                  borderColor: "#6b7280",
                  borderRadius: 4,
                }),
              }}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const USER_SELECT_FIELD_QUERY_DOCUMENT = gql(`
  query UserSelectFieldQuery($userId: ID!) {
    user(userId: $userId) {
      ...User_Avatar
    }
  }
`)
