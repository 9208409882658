import { createContext, useContext, useEffect, useMemo } from "react"
import invariant from "tiny-invariant"
import { CommunitySlug, DebugSettingKeyEnum } from "~/__generated__/graphql"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"

export enum CommunityFeature {
  benchmark = "benchmark",
  ask_the_bot = "ask_the_bot",
  referrals = "referrals",
  deepDive = "deep_dive",
  feed_upcoming_events = "feed_upcoming_events",
  feed_dive_in = "feed_dive_in",
  discount_code_after_posting = "discount_code_after_posting",
  new_in_library = "new_in_library",
}

type Community = {
  id: string
  name: string
  slug: CommunitySlug
  brand: string
  features: Array<CommunityFeature>
  lumaCalendarUrl: string
  lumaEventsUrl: string
  enabledCelebrationTypes: Array<string>
  defaultReactions: Array<string>
  welcomeVideoUrl: string | null
  termsOfUseUrl: string
  privacyPolicyUrl: string
  membershipAgreementUrl: string | null
  signUpUrl: string
  referralUrl: string
  referralsProfileBlurb: string
  referralsClipboardBlurb: string
  areApplicationsPaused: boolean
  creator: {
    id: string
    firstName: string
    lastName: string
    email: string
  }
  summit: {
    title: string
    description: string
    cta: {
      text: string
      url: string
    }
    display: boolean
  }
}

const CommunityContext = createContext<Community | null>(null)

export const CommunityProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { i18n } = useTranslation()
  const communityMetaContent = document
    .querySelector("meta[name=community]")
    ?.getAttribute("content")

  invariant(communityMetaContent, "Community slug meta tag missing")

  const value = useMemo(() => {
    const community = JSON.parse(communityMetaContent)
    invariant(
      Object.values(CommunitySlug).includes(community.slug as CommunitySlug),
      `Invalid community slug: ${community.slug}`
    )

    for (const feature of community.features) {
      invariant(
        Object.values(CommunityFeature).includes(feature as CommunityFeature),
        `Invalid community feature: ${feature}`
      )
    }
    return community as Community
  }, [communityMetaContent])

  useEffect(() => {
    i18n.changeLanguage(value.slug)
  }, [i18n, value])

  return (
    <CommunityContext.Provider value={value}>
      {children}
    </CommunityContext.Provider>
  )
}

export const useCommunity = () => {
  const ctx = useContext(CommunityContext)
  invariant(ctx, "useCommunity must be used within a CommunityProvider")
  return ctx
}

type DefaultRequired = { default: string; [key: string]: any }
type OptionsWithDefault = Partial<Record<CommunitySlug | "default", string>> &
  DefaultRequired
type OptionsWithoutDefault = Record<CommunitySlug, string>

const hasDefault = (
  opts: OptionsWithoutDefault | OptionsWithDefault
): opts is OptionsWithDefault => {
  const coerced = opts as OptionsWithDefault
  return coerced.default !== undefined
}

export const useCommunityClassname = () => {
  const ctx = useContext(CommunityContext)
  invariant(ctx, "useCommunity must be used within a CommunityProvider")

  return (entries: OptionsWithDefault | OptionsWithoutDefault) => {
    const matched = entries[ctx.slug]

    if (!matched && hasDefault(entries)) {
      return entries["default"]
    } else if (!matched) {
      return "no-community-match"
    }

    return matched
  }
}

export function useCommunityFeatures() {
  const { features } = useCommunity()
  let featureMap: Record<CommunityFeature, boolean> = {
    new_in_library: features.includes(CommunityFeature.new_in_library),
    benchmark: features.includes(CommunityFeature.benchmark),
    referrals: features.includes(CommunityFeature.referrals),
    deep_dive: features.includes(CommunityFeature.deepDive),
    ask_the_bot: features.includes(CommunityFeature.ask_the_bot),
    discount_code_after_posting: features.includes(
      CommunityFeature.discount_code_after_posting
    ),
    feed_upcoming_events: features.includes(
      CommunityFeature.feed_upcoming_events
    ),
    feed_dive_in: features.includes(CommunityFeature.feed_dive_in),
  }
  return featureMap
}

export function useDebugSettings() {
  const { data, loading } = useQuery(DEBUG_SETTINGS_QUERY_DOCUMENT)

  const debugSettings = useMemo(() => {
    if (loading || !data?.debugSettings) {
      return null
    }

    return data?.debugSettings.reduce(
      (
        acc: Record<DebugSettingKeyEnum, any>,
        { key, value }: { key: DebugSettingKeyEnum; value: any }
      ) => ({ ...acc, [key]: value }),
      {} as Record<DebugSettingKeyEnum, any>
    )
  }, [data, loading])

  return { debugSettings, loading }
}

const DEBUG_SETTINGS_QUERY_DOCUMENT = gql(`
  query DebugSettingsQuery {
    debugSettings {
      id
      key
      value
    }
  }
`)
