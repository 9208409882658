import { Check } from "lucide-react"
import {
  AhoyEventTypeEnum,
  CachedStripeInvoice,
  StripeInvoice,
  Tier,
  TierIntervalEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { useCurrentUser, useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { formatCurrency } from "~/common/formatCurrency"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent, CardHeader } from "~/shadcn/ui/card"
import { DialogFooter, DialogHeader, DialogTitle } from "~/shadcn/ui/dialog"
import { useTiers } from "~/tiers/TiersProvider"
import { useWizard } from "~/ui/Wizard"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Switch } from "~/shadcn/ui/switch"
import { useSubscription } from "../SubscriptionProvider"
import { Badge } from "~/shadcn/ui/badge"
import { useLogEvent } from "~/analytics/EventsContext"

export type PricingTableTier = Pick<
  Tier,
  "id" | "level" | "name" | "active" | "position" | "permissions"
> & {
  yearlyStripePrice?: {
    unitAmount: number
  } | null
  quarterlyStripePrice?: {
    unitAmount: number
  } | null
}

export type PricingTableInvoice = Pick<
  CachedStripeInvoice,
  "id" | "amountPaid" | "status" | "paid"
>
export type PricingTableUpcomingInvoice = Pick<StripeInvoice, "amountRemaining">

export const PricingTableStep = () => {
  const { currentUser } = useCurrentUser()
  const { tiers, formatTierName } = useTiers()
  const { close, goToStep, meta, addToMeta } = useWizard()
  const { redirectToStripe } = useSubscription()
  const [selectedTier, setSelectedTier] = useState<PricingTableTier | null>(
    null
  )
  const [selectedInterval, setSelectedInterval] = useState<TierIntervalEnum>(
    TierIntervalEnum.Year
  )
  const [showAllTiers, setShowAllTiers] = useState(false)
  const [disableSelectCurrentTier, setDisableSelectCurrentTier] = useState(true)
  const { logEvent } = useLogEvent()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    if (meta.showAllTiers) {
      hasLoggedEvent.current = true
      setShowAllTiers(true)
    } else {
      logEvent(
        AhoyEventTypeEnum.UpgradeModalViewed,
        {
          user_current_tier: currentUser.tier?.level,
          source: meta.source || "unknown",
        },
        hasLoggedEvent
      )
    }
    return () => {
      addToMeta("showAllTiers", false)
    }
  }, [meta, addToMeta, logEvent, currentUser.tier])

  useEffect(() => {})

  useEffect(() => {
    setDisableSelectCurrentTier(
      meta.disableSelectCurrentTier === undefined
        ? true
        : meta.disableSelectCurrentTier
    )
  }, [meta.disableSelectCurrentTier])

  const presentableTiers = useMemo(() => {
    return showAllTiers
      ? tiers
      : tiers.filter((tier) => {
          if (tier.id === currentUser.tier?.id) return true
          if (tier.level === TierLevelEnum.Free) return false
          if (currentUser.tier?.level === TierLevelEnum.Pro) return false
          return tier.active
        })
  }, [tiers, currentUser.tier, showAllTiers])

  useEffect(() => {
    if (currentUser.tier?.level === TierLevelEnum.Free) {
      setSelectedTier(
        presentableTiers.find((t) => t.level === TierLevelEnum.Plus)!
      )
    } else {
      setSelectedTier(
        presentableTiers.find((t) => t.level === TierLevelEnum.Pro)!
      )
    }
  }, [currentUser.tier, presentableTiers])

  const changeTier = () => {
    if (!selectedTier) return
    if (!currentUser.activeStripeSubscription) {
      redirectToStripe(selectedTier.level, selectedInterval)
      return
    }
    addToMeta("previousTier", currentUser.tier)
    addToMeta("selectedTier", selectedTier)
    addToMeta("selectedInterval", selectedInterval)
    goToStep("MigrateToTierStep", "forward", false)
  }

  const selectTier = useCallback(
    (tier: PricingTableTier, interval: TierIntervalEnum) => {
      setSelectedTier(tier)
      setSelectedInterval(interval)
    },
    [setSelectedTier]
  )

  const title = useMemo(() => {
    if (meta.pricingTableStepTitle) {
      return meta.pricingTableStepTitle
    }
    return "Ready to upgrade your membership?"
  }, [meta.pricingTableStepTitle])

  const selectedTierName = useMemo(
    () => formatTierName(selectedTier, selectedInterval),
    [selectedTier, selectedInterval, formatTierName]
  )

  const cta = useMemo(() => {
    if (!selectedTier) return "Select a membership level"
    if (!currentUser.activeStripeSubscription)
      return `Subscribe to ${selectedTierName}`
    if (currentUser.tier?.id === selectedTier?.id) {
      if (currentUser.tierInterval === selectedInterval) {
        return `Stay on your ${selectedTierName}`
      } else {
        return `Switch to ${selectedTierName}`
      }
    }

    if (selectedTier.position > (currentUser.tier?.position || 0)) {
      return `Upgrade to ${selectedTierName}`
    } else {
      return `Downgrade to ${selectedTierName}`
    }
  }, [selectedTier, currentUser, selectedInterval, selectedTierName])

  return (
    <>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>

      <div
        className={cn(
          "grid grid-cols-1 gap-4 w-full",
          presentableTiers.length === 3 && "sm:grid-cols-3 sm:w-[896px]",
          presentableTiers.length === 2 && "sm:grid-cols-2 sm:w-[592px]"
        )}
      >
        {presentableTiers.map((tier) => (
          <PricingTier
            tier={tier}
            selectable={
              !disableSelectCurrentTier || currentUser.tier?.id !== tier.id
            }
            quarterly={
              currentUser.tier?.id === tier.id &&
              currentUser.tierInterval === TierIntervalEnum.Quarter
            }
            key={tier.id}
            selected={selectedTier?.id === tier.id}
            onClick={selectTier}
          />
        ))}
      </div>

      <DialogFooter className="sm:flex-col gap-6 items-center space-x-0">
        <Button type="button" onClick={changeTier} disabled={!selectedTier}>
          {cta}
        </Button>
        <Button type="button" variant="link" size="inline" onClick={close}>
          Nevermind
        </Button>
      </DialogFooter>
    </>
  )
}

export const PricingTier = ({
  tier: tierFromProps,
  selectable = true,
  quarterly,
  selected,
  onClick,
  showToggle = true,
  showCurrentBadge = true,
  amountDifference,
  discountedPrice,
  className,
}: {
  tier: PricingTableTier
  selectable?: boolean
  quarterly: boolean
  selected?: boolean
  onClick?: (tier: PricingTableTier, interval: TierIntervalEnum) => void
  showToggle?: boolean
  showCurrentBadge?: boolean
  amountDifference?: string | null
  discountedPrice?: number
  className?: string
}) => {
  const { currentUser } = useCurrentUserMaybe()
  const { tiers } = useTiers()
  const [isAnnualToggled, setIsAnnualToggled] = useState(!quarterly)

  const tier = useMemo(() => {
    if (tierFromProps.permissions) {
      return tierFromProps
    } else {
      return tiers.find((t) => t.id === tierFromProps.id)!
    }
  }, [tiers, tierFromProps])

  const hasBothPricingIntervals = useMemo(() => {
    return tier.quarterlyStripePrice && tier.yearlyStripePrice
  }, [tier])

  const isCurrentPlan = useMemo(() => {
    return (
      currentUser &&
      currentUser.tier?.id === tier.id &&
      (isAnnualToggled
        ? currentUser.tierInterval === TierIntervalEnum.Year
        : currentUser.tierInterval === TierIntervalEnum.Quarter)
    )
  }, [currentUser, tier, isAnnualToggled])

  const handleSwitchClicked = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (hasBothPricingIntervals && selectable) {
        const newIsAnnualToggled = !isAnnualToggled
        setIsAnnualToggled(newIsAnnualToggled)
        onClick &&
          onClick(
            tier,
            newIsAnnualToggled
              ? TierIntervalEnum.Year
              : TierIntervalEnum.Quarter
          )
      }
    },
    [
      hasBothPricingIntervals,
      setIsAnnualToggled,
      isAnnualToggled,
      onClick,
      tier,
      selectable,
    ]
  )

  const marketingFeatures: [string, boolean][] = useMemo(() => {
    return [
      ["Discussions", true],
      ["Content library", true],
      ["Member directory", true],
      ["Private virtual events", tier.permissions.canAttendPrivateEvents],
      ["Direct messages", tier.permissions.canInitiateDms],
      ["1:1 introductions", tier.permissions.canUseIntroductions],
      ["Courses", tier.permissions.canViewCourses],
      ["Premium content", tier.permissions.canViewPremiumArticles],
      ["Premium virtual events", tier.permissions.canViewProEvents],
      ["Annual in-person summit", tier.permissions.canViewProEvents],
    ]
  }, [tier])

  return (
    <Card
      variant="pricingTable"
      state={selected ? "selected" : "default"}
      onClick={() =>
        selectable &&
        onClick &&
        onClick(
          tier,
          isAnnualToggled ? TierIntervalEnum.Year : TierIntervalEnum.Quarter
        )
      }
      className={cn(
        "w-full sm:w-fit sm:min-w-72 relative",
        selectable && "cursor-pointer",
        className
      )}
    >
      <CardHeader>
        <div className="font-bold flex justify-between items-start text-left">
          <div>
            <span>{tier.name}</span>
            <div className="text-2xl font-medium my-2">
              <span className={cn(discountedPrice && "line-through mr-2")}>
                {formatCurrency(
                  !isAnnualToggled
                    ? tier.quarterlyStripePrice!.unitAmount
                    : tier.yearlyStripePrice!.unitAmount
                )}
              </span>
              {discountedPrice && (
                <span>{formatCurrency(discountedPrice)}</span>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {showToggle && hasBothPricingIntervals && (
              <Switch
                checked={isAnnualToggled}
                onClick={handleSwitchClicked}
                variant="selector"
                options={["Quarterly", "Annual"]}
              />
            )}
            {showCurrentBadge && isCurrentPlan && (
              <div className="text-right">
                <Badge className="text-4xs">Current</Badge>
              </div>
            )}
          </div>
        </div>
        <div className="text-sm font-normal h-12 text-left">
          {tier.level === TierLevelEnum.Free ? (
            <div></div>
          ) : (
            <>
              {!isAnnualToggled ? (
                <>
                  <div>Per Quarter</div>
                  <div className="text-xs italic">Billed every 12 weeks</div>
                </>
              ) : (
                <>
                  <div>Per Year</div>
                  <div></div>
                </>
              )}
            </>
          )}
        </div>
      </CardHeader>
      {discountedPrice && (
        <div className="absolute -right-6 -top-6 rounded-full bg-gradient-to-b from-highlight/90 to-highlight text-3xl text-white font-bold items-center justify-center text-center shadow-md -rotate-6 flex flex-col uppercase p-4 w-32 h-32">
          <div className="drop-shadow">{amountDifference}</div>
          <div className="drop-shadow">Off</div>
        </div>
      )}
      <CardContent className="pt-4 bg-porcelain text-2xs flex flex-col gap-1 rounded-b-lg">
        {marketingFeatures.map(([feature, checked]) => (
          <MarketingFeature key={feature} checked={checked}>
            {feature}
          </MarketingFeature>
        ))}
      </CardContent>
    </Card>
  )
}

const MarketingFeature = ({
  children,
  checked,
}: {
  children: React.ReactNode
  checked: boolean
}) => (
  <div className="flex gap-2 items-center">
    <div>
      <Check className={cn("w-3 h-3", !checked && "opacity-0")} />
    </div>
    <div>{children}</div>
  </div>
)

PricingTableStep.displayName = "PricingTableStep"
