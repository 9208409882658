import { zodResolver } from "@hookform/resolvers/zod"
import { PlusIcon, X } from "lucide-react"
import { useCallback, useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Input } from "~/shadcn/ui/input"
import { Separator } from "~/shadcn/ui/separator"
import { PollEditorFragment } from "./PollEditor"

const pollModalFormSchema = z.object({
  title: z.string().min(1, {
    message: "Question is required",
  }),
  options: z
    .array(
      z.object({
        title: z.string().min(1, {
          message: "Choice is required",
        }),
      })
    )
    .min(2, {
      message: "At least two choices are required",
    }),
})

type PollEditorModalFormValues = z.infer<typeof pollModalFormSchema>

type PollEditorModalProps = {
  onSave: (poll: PollEditorFragment) => void
  open: boolean
  poll?: PollEditorFragment | null
  setIsOpen: (value: boolean) => void
}

const MAX_OPTIONS = 5

export const PollEditorModal = ({
  onSave,
  open,
  poll,
  setIsOpen,
}: PollEditorModalProps) => {
  const [runCreatePoll] = useSafeMutation(CREATE_POLL_MUTATION)
  const [runUpdatePoll] = useSafeMutation(UPDATE_POLL_MUTATION)

  const form = useForm<PollEditorModalFormValues>({
    resolver: zodResolver(pollModalFormSchema),
    defaultValues: {
      title: poll?.title || "",
      options: poll?.options || [{ title: "" }, { title: "" }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "options",
  })

  useEffect(() => {
    form.reset({
      title: poll?.title || "",
      options: poll?.options || [{ title: "" }, { title: "" }],
    })
  }, [poll, form, open])

  const onSubmit = useCallback(
    async (values: PollEditorModalFormValues) => {
      const mutationInput = {
        title: values.title,
        options: values.options.map((o) => o.title),
      }
      if (poll) {
        const { data, errors } = await runUpdatePoll({
          variables: { input: { ...mutationInput, id: poll.id } },
        })
        if (errors || !data?.pollUpdate.poll) {
          toast.error("Failed to update poll")
          return
        }
        onSave(data.pollUpdate.poll)
      } else {
        const { data, errors } = await runCreatePoll({
          variables: { input: { ...mutationInput } },
        })
        if (errors || !data?.pollCreate.poll) {
          toast.error("Failed to create poll")
          return
        }
        onSave(data.pollCreate.poll)
      }
      setIsOpen(false)
    },
    [onSave, runCreatePoll, runUpdatePoll, poll, setIsOpen]
  )

  const formValues = form.getValues()

  const addChoice = useCallback(() => {
    const hasEmptyField = formValues.options.some((o) => o.title === "")
    if (hasEmptyField || formValues.options.length >= MAX_OPTIONS) {
      return
    }
    append({ title: "" })
  }, [append, formValues])

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <DialogHeader>
          <DialogTitle className="text-center">
            {poll ? "Edit Poll" : "Add Poll"}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-8"
          >
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Poll Question</FormLabel>
                  <FormControl>
                    <Input placeholder="Poll Question" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator />

            <div className="flex flex-col gap-2">
              <FormField
                control={form.control}
                name="options"
                render={() => (
                  <>
                    <FormLabel required>Poll Choices</FormLabel>
                    {fields.map((field, index) => (
                      <div key={field.id} className="flex gap-2 items-center">
                        <FormField
                          control={form.control}
                          name={`options.${index}.title` as const}
                          render={({ field }) => (
                            <FormItem className="flex-grow">
                              <FormControl>
                                <Input
                                  placeholder={`Choice ${index + 1}`}
                                  {...field}
                                  id={`options.${index}.title`}
                                  endAdornment={
                                    index > 1 ? (
                                      <Button
                                        onClick={() => {
                                          remove(index)
                                        }}
                                        variant="ghost"
                                        size="icon"
                                        className="bg-transparent"
                                        type="button"
                                      >
                                        <X className="w-4 h-4" />
                                      </Button>
                                    ) : undefined
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault()
                                      addChoice()
                                      const nextInput = document.getElementById(
                                        `options.${index + 1}.title`
                                      ) as HTMLInputElement
                                      if (nextInput) {
                                        nextInput.focus()
                                      }
                                    }

                                    if (
                                      e.key === "Backspace" &&
                                      field.value === ""
                                    ) {
                                      e.preventDefault()
                                      const input = document.getElementById(
                                        `options.${index - 1}.title`
                                      ) as HTMLInputElement
                                      if (input) {
                                        input.focus()
                                      }
                                      if (index > 1) {
                                        remove(index)
                                      }
                                    }
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        {index === fields.length - 1 &&
                          fields.length < MAX_OPTIONS && (
                            <Button
                              onClick={addChoice}
                              variant="ghost"
                              size="icon"
                              type="button"
                            >
                              <PlusIcon className="w-4 h-4" />
                            </Button>
                          )}
                      </div>
                    ))}
                  </>
                )}
              />
            </div>

            <Separator />

            <DialogFooter className="sm:justify-between items-center">
              <Button
                type="button"
                variant="link"
                size="inline"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button type="submit">
                {poll ? "Save Poll" : "Create Poll"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const CREATE_POLL_MUTATION = gql(`
  mutation CreatePoll($input: PollCreateInput!) {
    pollCreate(input: $input) {
      poll {
        id
        title
        editable
        options {
          id
          title
        }
      }
    }
  }
`)

const UPDATE_POLL_MUTATION = gql(`
  mutation UpdatePoll($input: PollUpdateInput!) {
    pollUpdate(input: $input) {
      poll {
        id
        title
        editable
        options {
          id
          title
        }
      }
    }
  }
`)
