import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { useCommunity } from "~/community/useCommunity"
import { Alert } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/shadcn/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { RadioGroup, RadioGroupItem } from "~/shadcn/ui/radio-group"
import { useWizard } from "~/ui/Wizard"
import { CancellationReasonEnum } from "../types"
import { cn } from "~/common/shadcn-utils"
import { Input } from "~/shadcn/ui/input"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum, TierLevelEnum } from "~/__generated__/graphql"
import { PricingTableTier } from "./PricingTableStep"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useTiers } from "~/tiers/TiersProvider"

const cancellationReasonFormSchema = z
  .object({
    cancellationReason: z.enum([
      CancellationReasonEnum.CannotExpense,
      CancellationReasonEnum.CannotAfford,
      CancellationReasonEnum.TooExpensive,
      CancellationReasonEnum.NotUsingEnough,
      CancellationReasonEnum.Other,
    ]),
    otherReason: z.string().optional(),
  })
  .refine(
    (data) => {
      const isOtherRequired =
        data.cancellationReason === CancellationReasonEnum.Other &&
        (!data.otherReason || data.otherReason?.trim().length === 0)
      return !isOtherRequired
    },
    {
      message: "Required",
      path: ["otherReason"],
    }
  )
type CancellationReasonFormValues = z.infer<typeof cancellationReasonFormSchema>

export const CancellationReasonStep = () => {
  const { back, goToStep, meta, addToMeta } = useWizard()
  const community = useCommunity()
  const { logEvent } = useLogEvent()
  const { currentUser } = useCurrentUser()
  const { formatTierName } = useTiers()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.CancellationReasonModalViewed,
      {},
      hasLoggedEvent
    )
  }, [logEvent])

  const selectedTier = useMemo(() => {
    if (meta.selectedTier) {
      return meta.selectedTier as PricingTableTier
    }
    return null
  }, [meta])

  const form = useForm<CancellationReasonFormValues>({
    resolver: zodResolver(cancellationReasonFormSchema),
    defaultValues: {
      cancellationReason: undefined,
      otherReason: "",
    },
  })

  const onSubmit = async (values: CancellationReasonFormValues) => {
    addToMeta("cancellationReason", values.cancellationReason)
    addToMeta("otherReason", values.otherReason)

    logEvent(AhoyEventTypeEnum.CancellationReasonSelected, {
      cancellation_reason: values.cancellationReason,
      other_cancellation_reason: values.otherReason,
    })

    if (values.cancellationReason === CancellationReasonEnum.CannotExpense) {
      goToStep("PreSpecialOfferStep", "forward", false)
    } else if (
      values.cancellationReason === CancellationReasonEnum.CannotAfford ||
      values.cancellationReason === CancellationReasonEnum.TooExpensive
    ) {
      goToStep("TakeAnExpenseTemplateStep")
    } else if (
      values.cancellationReason === CancellationReasonEnum.NotUsingEnough ||
      values.cancellationReason === CancellationReasonEnum.Other
    ) {
      if (community.id === "boardroom") {
        goToStep("BoardRoomBeforeYouMakeTheCallStep")
      } else if (community.id === "safespace") {
        goToStep("SafeSpaceBeforeYouMakeTheCallStep")
      } else if (community.id === "marketingland") {
        goToStep("MarketinglandBeforeYouMakeTheCallStep")
      } else {
        goToStep("ConfirmCancelStep")
      }
    }
  }

  const [currentCancellationReason, setCurrentCancellationReason] = useState<
    string | null
  >(null)

  return (
    <>
      <DialogHeader>
        <DialogTitle>We're sad to see you go</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-8"
        >
          <Alert className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="cancellationReason"
              render={({ field }) => (
                <FormItem className="space-y-6">
                  <FormLabel required className="font-semibold">
                    Why are you{" "}
                    {selectedTier?.level === TierLevelEnum.Plus
                      ? "downgrading"
                      : "cancelling"}{" "}
                    your {community.name}{" "}
                    {formatTierName(currentUser.tier, currentUser.tierInterval)}{" "}
                    today?
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={(value) => {
                        field.onChange(value)
                        setCurrentCancellationReason(value)
                      }}
                      defaultValue={field.value}
                    >
                      <FormItem variant="radio">
                        <FormControl>
                          <RadioGroupItem
                            value={CancellationReasonEnum.CannotExpense}
                          />
                        </FormControl>
                        <FormLabel>
                          I am unable to expense my membership
                        </FormLabel>
                      </FormItem>
                      <FormItem variant="radio">
                        <FormControl>
                          <RadioGroupItem
                            value={CancellationReasonEnum.CannotAfford}
                          />
                        </FormControl>
                        <FormLabel>I can't afford the membership</FormLabel>
                      </FormItem>
                      <FormItem variant="radio">
                        <FormControl>
                          <RadioGroupItem
                            value={CancellationReasonEnum.TooExpensive}
                          />
                        </FormControl>
                        <FormLabel>
                          I believe the membership is too expensive
                        </FormLabel>
                      </FormItem>
                      <FormItem variant="radio">
                        <FormControl>
                          <RadioGroupItem
                            value={CancellationReasonEnum.NotUsingEnough}
                          />
                        </FormControl>
                        <FormLabel>
                          I am not using my membership enough
                        </FormLabel>
                      </FormItem>
                      <FormItem variant="radio">
                        <FormControl>
                          <RadioGroupItem
                            value={CancellationReasonEnum.Other}
                          />
                        </FormControl>
                        <FormLabel>Other</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="otherReason"
              render={({ field }) => (
                <FormItem
                  className={cn(
                    currentCancellationReason === CancellationReasonEnum.Other
                      ? ""
                      : "hidden"
                  )}
                >
                  <FormControl>
                    <Input
                      placeholder="Please tell us why you'd like to cancel your membership"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Alert>

          <DialogFooter>
            <Button
              type="button"
              variant="link"
              size="inline"
              onClick={() => back()}
            >
              Back
            </Button>
            <Button type="submit">Continue</Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  )
}

CancellationReasonStep.displayName = "CancellationReasonStep"
