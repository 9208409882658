import { useEffect, useRef } from "react"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { RenderedArticle } from "~/editor/RenderedArticle"
import { libraryPath, articlePath, signInPath } from "~/common/paths"
import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { Card, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { gql } from "~/__generated__"
import {
  AhoyEventTypeEnum,
  ArticleContentTypeEnum,
} from "~/__generated__/graphql"
import { JustBookmarkedCardHeader } from "~/bookmarks/JustBookmarkedCardHeader"
import { cn } from "~/common/shadcn-utils"
import { TagAndContentType } from "~/ui/TagBubble"
import { ContributeToCommunityCallout } from "~/articles/ContributeToCommunityCallout"
import { useUpvotes } from "~/post-composer/useUpvotes"
import { RepliesFeed } from "~/posts/RepliesFeed"
import { PostComposer } from "~/post-composer/PostComposer"
import { useArticleReplies } from "~/posts/useArticleReplies"
import { ComposerContextProvider } from "~/post-composer/ComposerContext"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"

import ArrowLeft from "~/images/icons/arrow-left.svg?react"
import ThumbsUpLight from "~/images/icons/thumbs-up-light.svg?react"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import { CourseArticleContent } from "~/content/CourseArticleContent"
import { LessonFooter } from "~/content/LessonFooter"
import { ArticleScreenLayout } from "~/layouts/ArticleScreenLayout"
import { BookmarkCourseModal } from "~/content/BookmarkCourseModal"
import { useLogEvent } from "~/analytics/EventsContext"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { Button } from "~/shadcn/ui/button"
import { Lock } from "lucide-react"
import { RetweetIcon } from "~/articles/RetweetIcon"
import { BookmarkIcon } from "~/articles/BookmarkIcon"
import { useArticleScreenAnalytics } from "~/articles/useArticleScreenAnalytics"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { SubscribeToReadArticleBanner } from "~/articles/SubscribeToReadArticleBanner"
import { UserName } from "~/directory/UserName"
import { ShareIcon } from "~/articles/ShareIcon"

export enum ArticleScreenPermissionEnum {
  Preview = "PREVIEW",
  Permitted = "PERMITTED",
  UpgradeRequired = "UPGRADE_REQUIRED",
  Pending = "PENDING",
}

export const ArticleScreen = () => {
  const { articleId } = useParams()
  invariant(articleId)

  const { currentUser } = useCurrentUserMaybe()
  const [showJustBookmarkedHeader, setShowJustBookmarkedHeader] =
    useState(false)
  const [permission, setPermission] = useState(
    ArticleScreenPermissionEnum.Pending
  )

  const { data, loading, error } = useQuery(LIVE_ARTICLE_QUERY_DOCUMENT, {
    variables: { articleId },
  })
  const { addUpvote, removeUpvote } = useUpvotes({ articleId })
  const articleReplies = useArticleReplies({ articleId, skip: !currentUser })
  const { openUserDialog } = useUserDialogContext()
  const { logEvent } = useLogEvent()
  const navigate = useNavigate()
  const hasLoggedEvent = useRef(false)

  const article = data?.article
  useArticleScreenAnalytics({ articleId, article })

  useEffect(() => {
    if (!currentUser && article?.allowExternalShare) {
      setPermission(ArticleScreenPermissionEnum.Preview)
    } else if (!currentUser && article && !article.allowExternalShare) {
      navigate(signInPath.pattern)
    } else if (
      article?.premium &&
      (!currentUser || !currentUser.permissions.canViewPremiumArticles)
    ) {
      logEvent(
        AhoyEventTypeEnum.PremiumContentAccessed,
        {
          content_id: article.id,
        },
        hasLoggedEvent
      )
      setPermission(ArticleScreenPermissionEnum.UpgradeRequired)
    } else if (article) {
      setPermission(ArticleScreenPermissionEnum.Permitted)
    }
  }, [currentUser, article, logEvent, navigate])

  if (loading) return <LoadingIndicatorCentered />
  if (error || !article) return <Error message="Error loading article." />

  const editorjsContent =
    article.approvedRevision?.editorjsContent ||
    article.approvedRevision?.editorjsContentPreview

  const approvedRevision = article.approvedRevision
  invariant(approvedRevision)

  return (
    <ArticleScreenLayout article={article}>
      <Card>
        {showJustBookmarkedHeader && <JustBookmarkedCardHeader />}
        <CardHeader className="min-h-[60px]">
          {currentUser && (
            <CardTitle>
              <div className="flex justify-between items-center">
                {article.approvedRevision?.contentType ===
                  ArticleContentTypeEnum.Lesson &&
                article.approvedLesson?.section.course ? (
                  <Link
                    to={articlePath({
                      articleId:
                        article.approvedLesson.section.course.articleId,
                    })}
                    className="flex-initial flex items-center gap-2"
                  >
                    <ArrowLeft />
                    Back to Course
                  </Link>
                ) : (
                  <Link
                    to={libraryPath.pattern}
                    className="flex-initial flex items-center gap-2"
                  >
                    <ArrowLeft />
                    Back to Library
                  </Link>
                )}
                {article && (
                  <div className="flex flex-1 justify-end text-placeholder">
                    <RetweetIcon article={article} />
                    <BookmarkIcon
                      article={article}
                      onBookmarkToggle={(bookmarkExists) =>
                        setShowJustBookmarkedHeader(bookmarkExists)
                      }
                    />
                    {article.allowExternalShare && (
                      <ShareIcon article={article} />
                    )}
                  </div>
                )}
              </div>
            </CardTitle>
          )}
        </CardHeader>

        <div className="px-6 md:px-10 xl:px-20 py-6 mb-4 border-b border-mercury">
          <div className="flex items-start mb-3 flex-wrap md:flex-nowrap">
            <StackedUserAvatars
              users={article.collaborators.map((c) => c.user)}
              avatarSize="header"
              opensUserDialog={!!currentUser}
            />

            <div className="md:ml-3 ml-1 mt-1">
              <div className="text-3xs text-[#666] mb-1">Authors</div>
              <div className="text-xs tracking-wide">
                {article.collaborators.map((c, index) => {
                  return (
                    <span key={c.user.id}>
                      <button
                        onClick={() => currentUser && openUserDialog(c.user.id)}
                      >
                        <UserName user={c.user} />
                      </button>
                      {index < article.collaborators.length - 1 && ", "}
                    </span>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="mb-4 text-3xl font-serif">
            {approvedRevision.title}
          </div>

          <div className="mb-3 text-[#666] tracking-wide">
            {approvedRevision.description}
          </div>

          <TagAndContentType
            tagName={approvedRevision.tag.name}
            contentType={approvedRevision.contentType}
            colorClass="text-foreground"
            borderClass="border-textColor"
          />
        </div>

        <div className="mx-6 md:mx-10 xl:mx-20 my-10">
          {permission === ArticleScreenPermissionEnum.Permitted ||
          permission === ArticleScreenPermissionEnum.Preview ? (
            <>
              {approvedRevision.contentType ===
              ArticleContentTypeEnum.Course ? (
                <CourseArticleContent course={article.approvedCourse} />
              ) : editorjsContent ? (
                <RenderedArticle
                  editorData={editorjsContent}
                  preview={permission === ArticleScreenPermissionEnum.Preview}
                />
              ) : null}
            </>
          ) : permission === ArticleScreenPermissionEnum.UpgradeRequired ? (
            <div className="h-96 flex items-center justify-center">
              <PromptToUpgrade />
            </div>
          ) : null}
        </div>

        {permission === ArticleScreenPermissionEnum.Permitted && (
          <>
            <LessonFooter article={article} />

            <div className="border-t mercury" />

            <div className="my-9 flex flex-col justify-center items-center">
              <div className="mb-4 font-semibold tracking-wide">
                Was this content relevant to you?
              </div>

              <div
                className={cn(
                  "text-xl flex gap-2 items-center border border-mercury rounded-full px-5 py-3 cursor-pointer",
                  {
                    "border-blue-500 bg-blue-50": article.currentUserUpvoted,
                  }
                )}
                onClick={(e) => {
                  article.currentUserUpvoted ? removeUpvote() : addUpvote()
                }}
              >
                <ThumbsUpLight height="24" />
                {article.upvotesCount > 0 && article.upvotesCount}
              </div>
            </div>

            <ComposerContextProvider composerRef={articleReplies.composerRef}>
              <div className="mx-6 md:mx-10 xl:mx-20">
                <PostComposer
                  onSave={articleReplies.createReply}
                  isSaving={articleReplies.replayIsSaving}
                  isReply
                  ref={articleReplies.composerRef}
                  title="Leave Feedback"
                />

                <div className="mt-8">
                  <RepliesFeed repliesResult={articleReplies.repliesResult} />
                </div>
              </div>
            </ComposerContextProvider>
            <BookmarkCourseModal article={article} />
          </>
        )}
      </Card>

      {permission === ArticleScreenPermissionEnum.Permitted && (
        <ContributeToCommunityCallout
          includeMyContentLink
          containerClassName="mt-4 mb-12"
        />
      )}

      {permission === ArticleScreenPermissionEnum.Preview && (
        <SubscribeToReadArticleBanner
          requiresPaidSubscription={article.premium}
        />
      )}
    </ArticleScreenLayout>
  )
}

const PromptToUpgrade = () => {
  const promptedOnceRef = useRef(false)
  const { openSubscriptionWizard } = useSubscription()

  useEffect(() => {
    if (promptedOnceRef.current) return
    promptedOnceRef.current = true
    openSubscriptionWizard("PricingTableStep", { source: "PremiumArticle" })
  }, [openSubscriptionWizard])

  return (
    <Button
      type="button"
      onClick={() => openSubscriptionWizard("PricingTableStep")}
    >
      Upgrade to view this content
      <Lock className="w-4 h-4 ml-1" />
    </Button>
  )
}

export const LIVE_ARTICLE_QUERY_DOCUMENT = gql(`
  query LiveArticleQueryDocument($articleId: ID!) {
    article(articleId: $articleId) {
      ...Article_LiveContent
    }
  }
`)

gql(`
  fragment Article_LiveContent on Article {
    id
    state
    visibility
    featuredUntil
    createdAt
    markedLiveAt
    currentUserUpvoted
    currentUserBookmark {
      ...Bookmark
    }
    upvotesCount
    premium
    allowExternalShare
    collaborators {
      id
      user {
        id
        name
        ...User_Avatar
      }
    }
    approvedRevision {
      id
      title
      description
      contentVersion
      tag {
        id
        name
      }
      contentType
      editorjsContent
      editorjsContentPreview
    }
    approvedCourse {
      ...Course_LiveContent
    }
    approvedLesson {
      id
      article {
        id
      }
      section {
        course {
          ...Course_LiveContent
        }
      }
    }
  }
`)
