import { VariantProps, cva } from "class-variance-authority"
import { forwardRef } from "react"
import { gql } from "~/__generated__"
import { CommunitySlug, User_AvatarFragment } from "~/__generated__/graphql"
import { imgixResize } from "~/common/imgix"
import { cn } from "~/common/shadcn-utils"
import { useCommunityClassname } from "~/community/useCommunity"
import { useUserDialogContextMaybe } from "~/directory/UserDialogContext"

const avatarVariants = cva("", {
  variants: {
    size: {
      "2xs": "w-[24px] h-[24px] text-[9px] leading-[24px] shrink-0",
      xs: "w-[28px] h-[28px] text-[10px] leading-[28px] shrink-0",
      sm: "w-[30px] h-[30px] text-[11px] leading-[30px] shrink-0",
      md: "w-[32px] h-[32px] text-[11px] leading-[32px] shrink-0",
      lg: "w-[38px] h-[38px] text-[14px] leading-[38px]",
      xl: "w-[46px] h-[46px] text-[16px] leading-[46px]",
      "2xl": "w-[60px] h-[60px] text-[22px] leading-[60px]",
      "3xl": "w-[94px] h-[94px] text-[34px] leading-[94px]",

      dialog: "w-[94px] h-[94px] text-[34px] leading-[94px]",
      cta: "w-[46px] h-[46px] text-[16px] leading-[46px]",
      onboarding: "w-[60px] h-[60px] text-[22px] leading-[60px]",
      smaller: "w-[28px] h-[28px] text-[10px] leading-[28px] shrink-0",
      default: "w-[30px] h-[30px] text-[11px] leading-[30px] shrink-0",
      preview: "w-[32px] h-[32px] text-[11px] leading-[32px] shrink-0",
      header: "w-[38px] h-[38px] text-[14px] leading-[38px] shrink-0",
      "post-autocomplete":
        "w-[24px] h-[24px] text-[9px] leading-[24px] shrink-0",
    },
  },
  defaultVariants: {
    size: "sm",
  },
})

const ringWidths: { [key: string]: number | undefined } = {
  preview: 1,
}

const paddingWidths: { [key: string]: number | undefined } = {
  default: 1,
  smaller: 1,
  "post-autocomplete": 1,
  preview: 2,
}

export interface AvatarWithFallbackProps
  extends VariantProps<typeof avatarVariants> {
  user: User_AvatarFragment
  className?: string
  style?: React.CSSProperties
  hasIndicator?: boolean
  textOverride?: string
  opensUserDialog?: boolean
  noBorder?: boolean
  nonAdminClassname?: string
}

export const AvatarWithFallback = forwardRef<
  HTMLElement,
  AvatarWithFallbackProps
>(
  (
    {
      user,
      size,
      className,
      style = {},
      hasIndicator = false,
      textOverride,
      noBorder = false,
      opensUserDialog = false,
      nonAdminClassname,
    },
    ref
  ) => {
    const avatarSize = size || "default"
    const ringWidth = ringWidths[avatarSize] || 2
    const paddingWidth = paddingWidths[avatarSize] || 3
    const userDialog = useUserDialogContextMaybe()
    const ccls = useCommunityClassname()

    const ComponentType = opensUserDialog ? "button" : "div"

    return (
      <ComponentType
        ref={ref as any}
        style={{
          borderWidth: noBorder ? 0 : ringWidth,
          padding: noBorder ? 0 : paddingWidth,
          ...style,
        }}
        className={cn(
          "relative shrink-0 rounded-full bg-white",
          user.admin || user.coach
            ? user.admin
              ? "border-yellow"
              : "border-coach"
            : cn("border-white", nonAdminClassname),
          opensUserDialog ? "cursor-pointer" : "",
          className
        )}
        onClick={() => {
          opensUserDialog && userDialog && userDialog.openUserDialog(user.id)
        }}
        type={opensUserDialog ? "button" : undefined}
      >
        {hasIndicator && (
          <div className="absolute bg-highlight h-[12px] w-[12px] top-0 right-0 rounded-full " />
        )}
        {user.photoUrl ? (
          <img
            src={imgixResize(user.photoUrl, {
              width: 192,
              height: 192,
              fit: "crop",
            })}
            alt={`${user.firstName} ${user.lastName}`}
            className={cn(
              avatarVariants({ size }),
              "object-cover rounded-full"
            )}
          />
        ) : (
          <div
            className={cn(
              avatarVariants({ size }),
              "rounded-full text-center text-white",
              ccls({
                [CommunitySlug.Marketingland]: "bg-marketingland-blue",
                default: "bg-onboarding-primary",
              })
            )}
          >
            {textOverride ||
              `${user.firstName ? user.firstName.toUpperCase().charAt(0) : ""}${
                user.lastName ? user.lastName.toUpperCase().charAt(0) : ""
              }`}
          </div>
        )}
      </ComponentType>
    )
  }
)

interface AvatarPlaceholderProps
  extends Omit<
    AvatarWithFallbackProps,
    "user" | "opensUserDialog" | "hasIndicator" | "textOverride"
  > {
  children?: React.ReactNode | string
}

export const AvatarPlaceholder = ({
  size,
  noBorder,
  style,
  nonAdminClassname,
  className,
  children,
}: AvatarPlaceholderProps) => {
  const avatarSize = size || "default"
  const ringWidth = ringWidths[avatarSize] || 2
  const paddingWidth = paddingWidths[avatarSize] || 3

  return (
    <div
      style={{
        borderWidth: noBorder ? 0 : ringWidth,
        padding: noBorder ? 0 : paddingWidth,
        ...style,
      }}
      className={cn(
        "relative shrink-0 rounded-full bg-white",
        cn("border-white", nonAdminClassname),
        className
      )}
    >
      <div
        className={cn(
          avatarVariants({ size }),
          "bg-onboarding-primary rounded-full text-center text-white"
        )}
      >
        {children}
      </div>
    </div>
  )
}

gql(`
  fragment User_Avatar on User {
    id
    firstName
    lastName
    name
    admin
    coach
    photoUrl
  }
`)
