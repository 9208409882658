import { useEffect, useMemo, useRef } from "react"
import { Alert } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/shadcn/ui/dialog"
import { Separator } from "~/shadcn/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { formatCurrency } from "~/common/formatCurrency"
import {
  AhoyEventTypeEnum,
  Offer,
  TierIntervalEnum,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useTiers } from "~/tiers/TiersProvider"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { PricingTier } from "./PricingTableStep"

export const SpecialOfferStep = () => {
  const { goToStep, close, meta, addToMeta } = useWizard()
  const { subscription, acceptOffer } = useSubscription()
  const { logEvent } = useLogEvent()
  const { formatTierName } = useTiers()
  const { currentUser } = useCurrentUser()
  const { tiers } = useTiers()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.OfferModalViewed,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  const offer = useMemo(() => {
    return meta.offer ? (meta.offer as Offer) : null
  }, [meta])

  const movingToInterval = useMemo(() => {
    if (!offer) return null
    return offer.effects.find((event: any) => event.type === "ChangeTier")
      ?.params.interval
  }, [offer])

  const userIsAlreadyOnTierAndInterval = useMemo(() => {
    if (!offer) return true
    return (
      currentUser.tier?.level === offer.tier.level &&
      movingToInterval === currentUser.tierInterval
    )
  }, [currentUser.tier, offer, movingToInterval, currentUser.tierInterval])

  const offerStripePrice = useMemo(() => {
    if (!offer) return null

    const tier = tiers.find((t) => t.id === offer.tier.id)
    if (!tier) return null

    return movingToInterval === TierIntervalEnum.Quarter
      ? tier.quarterlyStripePrice
      : tier.yearlyStripePrice
  }, [offer, tiers, movingToInterval])

  const currentPrice = useMemo(() => {
    if (!offerStripePrice || !subscription) return null

    let totalDiscounts = 0
    if (subscription.discounts) {
      subscription.discounts.forEach((discount) => {
        if (discount.coupon.amountOff) {
          totalDiscounts += discount.coupon.amountOff
        }

        if (discount.coupon.percentOff) {
          totalDiscounts +=
            offerStripePrice.unitAmount * (discount.coupon.percentOff / 100)
        }
      })
    }

    return Math.max(0.0, offerStripePrice.unitAmount - totalDiscounts)
  }, [subscription, offerStripePrice])

  const discountedPrice = useMemo(() => {
    if (!subscription || !offer || !offerStripePrice) return null

    if (offer.stripeCoupon.amountOff) {
      return Math.max(
        0.0,
        offerStripePrice.unitAmount - offer.stripeCoupon.amountOff
      )
    }

    if (offer.stripeCoupon.percentOff) {
      return Math.max(
        0.0,
        offerStripePrice.unitAmount -
          offerStripePrice.unitAmount * (offer.stripeCoupon.percentOff / 100)
      )
    }

    return null
  }, [subscription, offer, offerStripePrice])

  const amountDifference = useMemo(() => {
    if (currentPrice === null || discountedPrice === null || currentPrice === 0)
      return null

    if (offer?.stripeCoupon.percentOff) {
      return `${offer.stripeCoupon.percentOff}%`
    } else if (offer?.stripeCoupon.amountOff) {
      return formatCurrency(offer.stripeCoupon.amountOff)
    }

    return `${Math.floor((1 - discountedPrice / currentPrice) * 100)}%`
  }, [currentPrice, discountedPrice, offer])

  const continueToCancel = () => {
    logEvent(AhoyEventTypeEnum.CancellationContinued, {
      cancellation_reason: meta.cancellationReason,
      other_cancellation_reason: meta.otherReason,
    })
    goToStep("ConfirmCancelStep")
  }

  const keepMembership = async () => {
    if (!offer) return
    goToStep("LoadingStep", "forward", false)

    logEvent(AhoyEventTypeEnum.OfferAccepted, {
      cancellation_reason: meta.cancellationReason,
      other_cancellation_reason: meta.otherReason,
      stripe_coupon_id: offer.stripeCouponId,
    })

    const success = await acceptOffer(offer.id)
    addToMeta("offer", offer)
    addToMeta("selectedTier", offer.tier)
    addToMeta("selectedInterval", movingToInterval)

    if (success) {
      setTimeout(() => goToStep("SuccessStep"), 300)
    } else {
      close()
    }
  }

  if (!offer || !currentPrice || !discountedPrice) return null

  return (
    <div className="max-w-2xl flex flex-col gap-4">
      <DialogHeader>
        <DialogTitle>
          We hate to hear it. Here's what we can do to help you keep your{" "}
          {currentUser.tier?.name} Membership
        </DialogTitle>
      </DialogHeader>
      <Alert className="flex flex-col gap-4 text-center items-center justify-center">
        {userIsAlreadyOnTierAndInterval ? (
          <p>
            Keep your current membership benefits at a lower price. On your next
            billing cycle, you'll get a discount of {amountDifference} off your
            current membership price.
          </p>
        ) : (
          <strong>
            {currentUser.tier?.level !== offer.tier.level ? (
              <>
                Save {amountDifference} by switching to{" "}
                {formatTierName(offer.tier, movingToInterval)}.
              </>
            ) : (
              <>
                Switch to the{" "}
                {movingToInterval === TierIntervalEnum.Quarter
                  ? "quarterly"
                  : "annual"}{" "}
                membership and get {amountDifference} off{" "}
                {movingToInterval === TierIntervalEnum.Quarter
                  ? "three more months"
                  : "another year"}{" "}
                of {offer.tier.name} Membership.
              </>
            )}
          </strong>
        )}
        <PricingTier
          className="mt-2"
          tier={offer.tier}
          quarterly={movingToInterval === TierIntervalEnum.Quarter}
          selected={false}
          selectable={false}
          showToggle={false}
          showCurrentBadge={false}
          amountDifference={amountDifference}
          discountedPrice={discountedPrice}
        />
      </Alert>

      <Separator />

      <DialogFooter className="flex-col gap-6 items-center">
        <Button onClick={keepMembership}>Yes, I'll Keep My Membership</Button>
        <Button
          type="button"
          variant="link"
          size="inline"
          className="text-2xs"
          onClick={continueToCancel}
        >
          Continue to cancel{" "}
          {formatTierName(currentUser.tier, currentUser.tierInterval)}
        </Button>
      </DialogFooter>
    </div>
  )
}

SpecialOfferStep.displayName = "SpecialOfferStep"
